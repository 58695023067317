<template>
  <div>
    <div class="select-card">
      <div class="select-card-top">
        <div>收入数据</div>
      </div>

      <div class="select-card-bottom">
        <div
          class="cardCon"
          v-if="setTargetPermissions_temp?.length == 0"
          style="color: #ccc"
        >
          暂无导入权限
        </div>
        <div class="cardCon" v-for="(i, index) in income_data" :key="index">
          <p
            class="title"
            v-if="
              setTargetPermissions_temp &&
              setTargetPermissions_temp.indexOf(i.perssion) > -1
            "
          >
            {{ i.title }}
          </p>

          <div
            class="buttonCon"
            v-if="
              setTargetPermissions_temp &&
              setTargetPermissions_temp.indexOf(i.perssion) > -1
            "
          >
            <div
              v-for="(d, dindex) in i.buttonCon"
              :key="dindex"
              style="margin-right: 24px"
            >
              <el-button
                type="primary"
                plain
                size="small"
                v-if="d.type == 1"
                @click="get_downOptions(d.val, i)"
                >{{ d.value }}</el-button
              >
              <el-upload
                v-if="d.type == 2"
                class="upload-demo"
                action
                :on-change="
                  (file, fileList) => handleChange(file, fileList, d.val)
                "
                :auto-upload="false"
                :show-file-list="false"
                accept=".xls,.xlsx"
              >
                <el-button size="small" type="primary">导入数据</el-button>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="select-card" style="margin-top: 20px">
      <div class="select-card-top">
        <div>费用数据</div>
        <!-- <el-date-picker
          v-model="costMonth"
          type="month"
          placeholder="选择月"
          size="small"
          style="margin-left: 12px"
          format="yyyy-MM"
          value-format="yyyy-MM"
          @change="checkTimeType"
        >
        </el-date-picker> -->
      </div>
      <div
        class="select-card-bottom"
        style="display: flex; color: #ccc; flex-direction: row"
        v-if="setTargetPermissions_temp?.length == 0"
      >
        暂无导入权限
      </div>
      <div
        class="select-card-bottom"
        style="display: flex; flex-direction: row"
      >
        <div class="con">
          <div class="cardCon" v-for="(i, index) in cost_data_sj" :key="index">
            <p
              class="title"
              v-if="
                setTargetPermissions_temp &&
                setTargetPermissions_temp.indexOf(i.perssion) > -1
              "
            >
              {{ i.title }}
            </p>
            <div
              class="buttonCon"
              v-if="
                setTargetPermissions_temp &&
                setTargetPermissions_temp.indexOf(i.perssion) > -1
              "
            >
              <div
                v-for="(d, dindex) in i.buttonCon"
                :key="dindex"
                style="margin-right: 24px"
              >
                <el-button
                  type="primary"
                  plain
                  size="small"
                  v-if="dindex == 0"
                  @click="click_btn(1, i.type, d.type, i.title)"
                  >{{ d.value }}</el-button
                >
                <el-upload
                  v-else
                  class="upload-demo"
                  action
                  :on-change="
                    (file, fileList) =>
                      handleChange_cost(file, fileList, i.type, dindex, 1)
                  "
                  :auto-upload="false"
                  :show-file-list="false"
                  accept=".xls,.xlsx"
                >
                  <el-button size="small" type="primary">{{
                    d.value
                  }}</el-button>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
        <div class="con">
          <div class="cardCon" v-for="(i, index) in cost_data_ys" :key="index">
            <p
              class="title"
              v-if="
                setTargetPermissions_temp &&
                setTargetPermissions_temp.indexOf(i.perssion) > -1
              "
            >
              {{ i.title }}
            </p>
            <div
              class="buttonCon"
              v-if="
                setTargetPermissions_temp &&
                setTargetPermissions_temp.indexOf(i.perssion) > -1
              "
            >
              <div
                v-for="(d, dindex) in i.buttonCon"
                :key="dindex"
                style="margin-left: 10px"
              >
                <el-button
                  type="primary"
                  plain
                  size="small"
                  v-if="dindex == 0"
                  @click="click_btn(2, i.type, d.type, i.title)"
                  >{{ d.value }}</el-button
                >
                <el-upload
                  v-else
                  class="upload-demo"
                  action
                  :on-change="
                    (file, fileList) =>
                      handleChange_cost(file, fileList, i.type, dindex, 2)
                  "
                  :auto-upload="false"
                  :show-file-list="false"
                  accept=".xls,.xlsx"
                >
                  <el-button size="small" type="primary">{{
                    d.value
                  }}</el-button>
                </el-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      setTargetPermissions: "",
      setTargetPermissions_temp: [],
      // costMonth: "",
      changeType: false,
      mockName: "",
      fileList: "",
      income_data: [
        {
          title: "大区销售目标",
          perssion: 2,
          buttonCon: [
            { type: 1, value: "下载模板", val: 1 },
            { type: 2, value: "导入数据", val: 1 },
          ],
        },
        // {
        //   title: "代表销售目标",
        //   perssion: 3,
        //   buttonCon: [
        //     { type: 1, value: "下载模板", val: 2 },
        //     { type: 2, value: "导入数据", val: 2 },
        //   ],
        // },
        {
          title: "产品线销售目标",
          perssion: 4,
          buttonCon: [
            { type: 1, value: "下载模板", val: 3 },
            { type: 2, value: "导入数据", val: 3 },
          ],
        },
        {
          title: "销售业绩营管口径指标",
          perssion: 16,
          buttonCon: [
            { type: 1, value: "下载模板", val: 4 },
            { type: 2, value: "导入数据", val: 4 },
          ],
        },
      ],
      cost_data_sj: [
        {
          title: "实际人力成本",
          perssion: 6,
          type: 1,
          buttonCon: [
            { type: 1, value: "下载模板" },
            { type: 2, value: "新增导入" },
            { type: 3, value: "覆盖导入" },
          ],
        },

        {
          title: "实际销售奖金",
          type: 2,
          perssion: 8,
          buttonCon: [
            { type: 1, value: "下载模板" },
            { type: 2, value: "新增导入" },
            { type: 3, value: "覆盖导入" },
          ],
        },

        {
          title: "实际上市后学术研究费",
          perssion: 10,
          type: 3,
          buttonCon: [
            { type: 1, value: "下载模板" },
            { type: 2, value: "新增导入" },
            { type: 3, value: "覆盖导入" },
          ],
        },

        {
          title: "实际研发领料费",
          type: 4,
          perssion: 12,
          buttonCon: [
            { type: 1, value: "下载模板" },
            { type: 2, value: "新增导入" },
            { type: 3, value: "覆盖导入" },
          ],
        },
        {
          title: "实际专项推广费补充",
          type: 5,
          perssion: 13,
          buttonCon: [
            { type: 1, value: "下载模板" },
            { type: 2, value: "新增导入" },
          ],
        },
      ],
      cost_data_ys: [
        {
          title: "预算人力成本",
          perssion: 7,
          type: 1,
          buttonCon: [
            { type: 1, value: "下载模板" },
            { type: 2, value: "新增导入" },
            { type: 3, value: "覆盖导入" },
          ],
        },

        {
          title: "预算销售奖金",
          perssion: 9,
          type: 2,
          buttonCon: [
            { type: 1, value: "下载模板" },
            { type: 2, value: "新增导入" },
            { type: 3, value: "覆盖导入" },
          ],
        },

        {
          title: "预算上市后学术研究费",
          perssion: 11,
          type: 3,
          buttonCon: [
            { type: 1, value: "下载模板" },
            { type: 2, value: "新增导入" },
            { type: 3, value: "覆盖导入" },
          ],
        },

        {
          title: "预算研发领料费",
          perssion: 13,
          type: 4,
          buttonCon: [
            { type: 1, value: "下载模板" },
            { type: 2, value: "新增导入" },
            { type: 3, value: "覆盖导入" },
          ],
        },
        {
          title: "费控预算",
          type: 5,
          perssion: 14,
          buttonCon: [
            { type: 1, value: "下载模板" },
            { type: 2, value: "新增导入" },
            { type: 3, value: "覆盖导入" },
          ],
        },
      ],
    };
  },
  created() {
    console.log(2222222);

    this.setTargetPermissions = JSON.parse(
      sessionStorage.getItem("userInfo")
    ).setTargetPermissions;
    if (this.setTargetPermissions) {
      let a = JSON.parse(this.setTargetPermissions);
      let data = [];
      if (a || a?.length) {
        a.forEach((element) => {
          data.push(element[1]);
        });
      }

      this.setTargetPermissions_temp = data;
    }

    // this.defaultDate();
  },
  methods: {
    // defaultDate() {
    //   //获取新的时间
    //   let date = new Date();
    //   //获取当前时间的年份转为字符串
    //   let year = date.getFullYear().toString();
    //   //获取月份，由于月份从0开始，此处要加1，判断是否小于10，如果是在字符串前面拼接'0'
    //   let month =
    //     date.getMonth() + 1 < 10
    //       ? "0" + (date.getMonth() + 1).toString()
    //       : (date.getMonth() + 1).toString();
    //   //获取天，判断是否小于10，如果是在字符串前面拼接'0'
    //   let da =
    //     date.getDate() < 10
    //       ? "0" + date.getDate().toString()
    //       : date.getDate().toString();
    //   //字符串拼接，开始时间，结束时间
    //   let beg = year + "-" + month; //当月第一天
    //   this.costMonth = beg; //将值设置给插件绑定的数据
    // },
    get_downOptions(val, item) {
      console.log(item);
      this.mockName = `${item.title}模版`;
      // let a = { responseType: "blob" };
      const url = val === 4 ? "DOWNLOADTEMPLATE" : "SALEINCOME_DOWNLOAD";
      this.axios
        .get(url, val === 4 ? "" : val, {
          responseType: "blob", //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置
        })
        .then((result) => {
          const blob = new Blob([result]);
          console.log(blob);
          const time = new Date().getTime() / 1000;
          const fileName = this.mockName + ".xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    handleChange(file, fileList, val) {
      console.log("file---", file);
      this.fileList = file;
      // fileList = fileList.slice(-3);
      let formData = new FormData();
      formData.append("file", this.fileList.raw);
      val === 4 ? "" : formData.append("type", val);
      console.log(formData);
      this.upload_file(formData, val);
    },
    upload_file(val, type) {
      let params = val;
      this.$store.dispatch("app/setLoading", true);
      console.log(params);
      // let a = { responseType: "blob" };
      const url = type === 4 ? "IMPORTSALETARGET" : "SALEINCOME_UPLOAD";
      this.axios
        .post(url, val)
        .then((result) => {
          console.log("查询返回", result);
          if (result.retCode == "200" || result.code === 0) {
            this.$message({
              message: "导入成功",
              type: "success",
            });
          } else {
            const str = result.msg.replace(/(\n|\r|\r\n|↵)/g, "<br/>");
            this.$message({
              dangerouslyUseHTMLString: true,
              type: "error",
              message: str,
            });
          }
          this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    click_btn(type, val, dtype, title) {
      console.log(dtype);
      if (dtype == 1) {
        //下载
        this.cost_down(type, val, title);
      }
    },
    cost_down(type, val, title) {
      let url = "COST_DOWNLOADCOSTTEMPLATE"; //实际模版
      if (type == 2) {
        url = "COST_DOWNLOADBUDGETTEMPLATE"; //预算模版
      }
      this.$store.dispatch("app/setLoading", true);
      let params = `?categoryType=${val}`;
      this.axios
        .get(url, params, {
          responseType: "blob", //设置响应的数据类型为一个包含二进制数据的 Blob 对象，必须设置
        })
        .then((result) => {
          console.log("查询返回", result);
          const blob = new Blob([result]);
          console.log(blob);
          this.mockName = `${title}模版`;
          const time = new Date().getTime() / 1000;
          const fileName = this.mockName + ".xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
          this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
    checkTimeType(index) {
      // this.changeType = true;
      // 广播出去
      // Bus.$emit("overviewTimeType", this.timeType);
    },

    handleChange_cost(file, fileList, val, index, type) {
      this.fileList = file;
      let formData = new FormData();
      formData.append("file", this.fileList.raw);
      formData.append("categoryType", val);
      // let monthTemp = "";
      // monthTemp = this.costMonth.replace(/\-/g, "");
      // // this.costMonth =JSON.parse(JSON.stringify(monthTemp)) ;
      // console.log(
      //   "月份",
      //   monthTemp,
      //   "categoryType",
      //   val,
      //   "importMode",
      //   index
      // );
      // formData.append("costMonth", parseInt(monthTemp));
      formData.append("importMode", index);
      console.log(formData);
      this.upload_file_cost(formData, type);
    },
    upload_file_cost(val, type) {
      let params = val;
      this.$store.dispatch("app/setLoading", true);
      console.log(params);
      // let a = { responseType: "blob" };
      let url = "COST_IMPORTCOSTRECORD";
      if (type == 2) {
        url = "COST_IMPORTBUDGETRECORD";
      }
      this.axios
        .post(url, val)
        .then((result) => {
          console.log("查询返回", result);
          if (result.retCode == "200") {
            this.$message({
              message: "导入成功",
              type: "success",
            });
          } else {
            this.$message.error(result.msg);
          }
          this.$store.dispatch("app/setLoading", false);
        })
        .catch(() => {
          this.isLoading = false;
          this.$store.dispatch("app/setLoading", false);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.select-card {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  .con {
    flex: 1;
  }
  .cardCon {
    // padding: 24px;
    // box-sizing: border-box;

    // display: flex;
    margin-top: 44px;
    .title {
      font-size: 14px;
      padding: 16px 0;
      box-sizing: border-box;
    }
    .buttonCon {
      display: flex;
      padding: 0 0;
      box-sizing: border-box;
    }
  }
  .cardCon:first-child {
    margin-top: 0;
  }
  .select-card-top {
    color: #262626;
    font-weight: bold;
    font-size: 16px;
    padding: 13px 24px 13px 24px;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    flex-direction: row;
    align-items: center;
    .question {
      width: 18px;
      height: 18px;
      margin-left: 5px;
    }
    .question:hover {
      cursor: pointer;
    }
  }
  .select-card-bottom {
    padding: 30px 24px 30px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // align-items: center;
    .select-card-bottom-left {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .class-list {
        margin-left: 24px;
        width: 200px;
      }
    }
    .select-card-bottom-right {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}
</style>
